@import "~toastr/toastr";
@import "~select2/dist/css/select2.css";
@import "sb-admin-2.css";

td.acciones {
  width: 50px;
}

.table-form .form-group label {
  display: none;
}

label {
  &.required:before {
    content: "* ";
  }
}

.vich-image img {
  max-width: 100%;
}

.select2-container {
  width: 100%!important;

  .select2-selection--single {
    height: calc(2.25rem + 2px);
  }
}

.select2-container--default {
  .select2-selection--single {
    .select2-selection__rendered {
      line-height: calc(2.25rem + 2px);
    }

    .select2-selection__arrow {
      height: calc(2.25rem + 2px);
    }
  }
}
